import { render, staticRenderFns } from "./BuroInfo.vue?vue&type=template&id=583b3e6e&scoped=true"
import script from "./BuroInfo.vue?vue&type=script&lang=ts"
export * from "./BuroInfo.vue?vue&type=script&lang=ts"
import style0 from "./BuroInfo.vue?vue&type=style&index=0&id=583b3e6e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583b3e6e",
  null
  
)

export default component.exports